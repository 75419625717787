.overlay {
    display: flex;
    justify-content: center; /* Space between the title and search icon */
    align-items: center;
    width: 100%;
    padding: 20px 0; 
    position: absolute;
    z-index: 1000;
    background: linear-gradient(to bottom, rgb(137, 210, 253) 0%, rgb(255, 241, 188, 0.1) 100%);
}

.title-map-page {
    color: rgb(227, 203, 83);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center;
    font-size: 3em;
    text-shadow: 2px 4px 8px rgba(0, 0, 0, 1);
    margin: 0;
}

.map-container {
    height: 100dvh;
    width: 100%;
}

.search-icon {
    font-size: 26px;
}

.search-icon-button {
    position: absolute;
    top: 50%;  /* Centers vertically in the container */
    right: 30px; /* Distance from the right edge of the screen */
    transform: translateY(-50%); /* Vertically center icon */
    background: white;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
    cursor: pointer;
}

.search-icon-button:hover {
    background: #f0f0f0; /* Optional: change background on hover */
}

.centered-text {
    text-align: center; /* Center the text horizontally */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Make title and search icon a little larger for wider screens */
@media (min-width: 700px) {
    .title-map-page {
        font-size: 4em;
    }
    .search-icon {
        font-size: 30px;
    }
    .search-icon-button {
        background: white;
        border-radius: 50%;
        padding: 13px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
        cursor: pointer;
    }
}