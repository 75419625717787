.search-page {
    background: linear-gradient(to bottom, rgb(137, 210, 253) 0%, rgb(255, 241, 188) 70%);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(89, 78, 41);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
}

.title {
    color: rgb(227, 203, 83);
    text-align: center;
    font-size: 3.5em;
    text-shadow: 2px 2px 7px rgba(0, 0, 0, 1);
}

@media (min-width: 700px) {
    .title {
        font-size: 4em;
    }
}
  
.container {
    background: radial-gradient(circle at 10% 20%, rgb(226, 240, 254) 0%, rgb(255, 247, 228) 90%);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    min-width: 300px;
    width: 40vw;
    margin-bottom: 8%;
}
  
.custom-radio {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
  
.custom-radio input {
    margin-right: 10px;
}
  
.custom-checkbox input {
    margin: 5px;
    margin-left: 30px;
}
  
.search-bar {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.search-input {
    flex: 1;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
}

button {
    padding: 8px 15px;
    background-color: rgb(137, 210, 253);
    border-color: white;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: rgb(99, 196, 252);
}

.back-button {
    position: absolute; /* Position it absolutely */
    top: 20px; /* Adjust the top position */
    left: 20px; /* Adjust the left position */
    background-color: transparent; /* Make background transparent */
    border: none; /* Remove border */
    cursor: pointer; /* Change cursor to pointer */
    color: #000; /* Change color as needed */
    font-size: 1.2rem; /* Adjust font size */
}